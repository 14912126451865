<template>
  <DetailTemplate
    :customClass="'proposal-detail detail-page'"
    v-if="getPermission('proposal:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ proposalSubject }}
          </h1>
          <CustomStatus
            :status.sync="proposalDetail.status"
            endpoint="proposals/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateMoreAction('edit')"
      >
        Edit <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="red lighted-1"
        v-on:click="updateMoreAction('delete')"
      >
        Delete <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in proposalDetail.more_actions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="6">
            <table width="100%">
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Proposal Subject
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ proposalDetail.subject }}
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Name
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.name">{{
                    proposalDetail.name
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Name</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Company
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.company">{{
                    proposalDetail.company
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Company </em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Email
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="proposalDetail.email">{{
                    proposalDetail.email
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Email </em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Phone Number
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.phone_number">{{
                    proposalDetail.phone_number
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Phone Number</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Website
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="proposalDetail.website">{{
                    proposalDetail.website
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Website</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Proposal Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.date">{{
                    formatDate(proposalDetail.date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Proposal Date</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Open Till Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.open_till">{{
                    formatDate(proposalDetail.open_till)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary"
                      >No Open Till Date</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Sent Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.sent_date">{{
                    formatDate(proposalDetail.sent_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Sent Date</em></template
                  >
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="6">
            <table width="100%">
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Proposal #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ proposalDetail.barcode }}
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Reference #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.reference">{{
                    proposalDetail.reference
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Reference #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Address
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.address">{{
                    proposalDetail.address
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Address</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Country
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.country">{{
                    proposalDetail.country
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Country</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  State
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.state">{{
                    proposalDetail.state
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No State</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  City
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.city">{{
                    proposalDetail.city
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No City</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Postal Code
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.zip">{{
                    proposalDetail.zip
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Postal Code</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Assigned Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.date_assigned">{{
                    formatDate(proposalDetail.date_assigned)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Assigned Date</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Assigned
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="proposalDetail.assigned_user">{{
                    proposalDetail.assigned_user.full_name
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No User Assigned</em></template
                  >
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" class="mt-8">
            <v-tabs
              active-class="custom-tab-active"
              v-model="proposalTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#other"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/proposal.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <template v-if="getPermission('line-item:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="lineItemCount"
                    bordered
                    color="orange darken-4"
                    :content="lineItemCount"
                  >
                    {{ lineItemCount > 1 ? "Line Item" : "Line Item" }}
                  </v-badge>
                  <template v-else> Line Item </template>
                </v-tab>
              </template>
              <template v-if="getPermission('history:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </template>
            </v-tabs>
            <v-tabs-items v-model="proposalTab">
              <v-tab-item value="other">
                <LineItemOnlyView
                  type="proposal"
                  :detail.sync="proposalDetail"
                  :pageLoading.sync="pageLoading"
                ></LineItemOnlyView>
                <InternalNoteAttachmentDetail
                  type="proposal"
                  isProposal
                  :detail.sync="proposalDetail"
                ></InternalNoteAttachmentDetail>
              </v-tab-item>
              <template v-if="getPermission('line-item:view')">
                <v-tab-item value="line-item">
                  <LineItemDetail
                    type="proposal"
                    :detail.sync="proposalDetail"
                    :pageLoading.sync="pageLoading"
                    v-on:load:parent="getProposal"
                  ></LineItemDetail>
                </v-tab-item>
              </template>
              <template v-if="getPermission('history:view')">
                <v-tab-item value="history">
                  <InternalHistoryDetail
                    type="proposal"
                    :type_id.sync="proposalDetail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <ProposalDelete
        :deleteDialog.sync="proposalDeleteDialog"
        :requestUrl.sync="proposalDeleteRequestUrl"
        v-on:delete:success="proposalDeleteSuccess"
        v-on:delete:close="proposalDeleteDialog = false"
      ></ProposalDelete>
      <template v-if="customer">
        <template v-if="customerPersonDialog">
          <CustomerPersonDialog
            :customerPersonDialog.sync="customerPersonDialog"
            :customer.sync="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerPerson="selectCustomerPerson"
          ></CustomerPersonDialog>
        </template>
        <template v-if="customerPropertyDialog">
          <CustomerPropertyDialog
            :customerPropertyDialog.sync="customerPropertyDialog"
            :customer.sync="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          ></CustomerPropertyDialog>
        </template>
      </template>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import ProposalDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import LineItemDetail from "@/view/pages/partials/Detail/Line-Item-Detail.vue";
import InternalNoteAttachmentDetail from "@/view/pages/partials/Detail/Internal-Note-Attachment-Detail.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import LineItemOnlyView from "@/view/pages/partials/Line-Item-Only-View.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "proposal-detail",
  title: "Detail Proposal",
  data() {
    return {
      proposal: null,
      customer: null,
      customerPerson: null,
      customerProperty: null,
      proposalTab: null,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      proposalDeleteDialog: false,
      pageLoading: false,
      proposalDetail: new Object({
        id: null,
        barcode: null,
        reference: null,
        customer: new Object(),
        lead: new Object(),
        quotation: new Object(),
        assigned_user: new Object(),
        name: null,
        company: null,
        email: null,
        website: null,
        phone_number: null,
        country: null,
        zip: null,
        city: null,
        state: null,
        address: null,
        subject: null,
        status: null,
        assigned: null,
        is_public: null,
        admin_remark: null,
        client_remark: null,
        additional_remarks: null,
        total: null,
        sub_total: null,
        discount_value: null,
        tax_amount: null,
        adjustment: null,
        discount_type: null,
        discount_value_type: null,
        date: null,
        open_till: null,
        date_assigned: null,
        sent_date: null,
        date_converted: null,
        total_items: null,
        added_by: null,
        added_at: null,
        updated_by: null,
        updated_at: null,
        created_at: null,
        modified_at: null,
        status_text: null,
        line_items: [],
        more_actions: new Array(),
        documents: new Array()
      })
    };
  },
  components: {
    LineItemOnlyView,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    DetailTemplate,
    CustomStatus,
    InternalHistoryDetail,
    LineItemDetail,
    InternalNoteAttachmentDetail,
    ProposalDelete
  },
  methods: {
    resetAll() {
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkQuotation();
    },
    checkQuotation() {
      const _this = this;
      let customer = _this.lodash.toSafeInteger(_this.customer);
      let customerPerson = _this.lodash.toSafeInteger(_this.customerPerson);
      let customerProperty = _this.lodash.toSafeInteger(_this.customerProperty);

      if (customer > 0 && customerPerson > 0 && customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: {
              customer: customer,
              contact_person: customerPerson,
              property: customerProperty,
              proposal: _this.proposal
            }
          })
        );
      }
    },
    proposalDeleteSuccess() {
      this.proposalDeleteDialog = false;
      this.goBack();
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("proposal.update", {
              params: {
                id: _this.proposal
              }
            })
          );
          break;
        case "convert_to_customer":
          _this.$router.push(
            _this.getDefaultRoute("customer.create", {
              query: {
                proposal: _this.proposal
              }
            })
          );
          break;
        case "convert_to_quotation":
          _this.customerPersonDialog = true;
          break;
        case "delete":
          _this.proposalDeleteDialog = true;
          break;
        case "mark_as_draft":
          _this.updateProposal({ status: 1 });
          break;
        case "mark_as_send":
          _this.updateProposal({ status: 2 });
          break;
        case "mark_as_open":
          _this.updateProposal({ status: 3 });
          break;
        case "mark_as_revised":
          _this.updateProposal({ status: 4 });
          break;
        case "mark_as_declined":
          _this.updateProposal({ status: 5 });
          break;
        case "mark_as_accepted":
          _this.updateProposal({ status: 6 });
          break;
      }
    },
    updateProposal(data) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "proposals/" + _this.proposal,
          data: data
        })
        .then(() => {
          _this.getProposal();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getProposal() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/" + _this.proposal
        })
        .then(({ data }) => {
          _this.proposalDetail = new Object({
            id: data.id ? data.id : null,
            barcode: data.barcode ? data.barcode : null,
            reference: data.reference ? data.reference : null,
            customer: data.customer ? data.customer : new Object(),
            lead: data.lead ? data.lead : new Object(),
            quotation: data.quotation ? data.quotation : new Object(),
            assigned_user: data.assigned_user
              ? data.assigned_user
              : new Object(),
            name: data.name ? data.name : null,
            company: data.company ? data.company : null,
            email: data.email ? data.email : null,
            website: data.website ? data.website : null,
            phone_number: data.phone_number ? data.phone_number : null,
            country: data.country ? data.country : null,
            zip: data.zip ? data.zip : null,
            city: data.city ? data.city : null,
            state: data.state ? data.state : null,
            address: data.address ? data.address : null,
            subject: data.subject ? data.subject : null,
            status: data.status ? data.status : null,
            assigned: data.assigned ? data.assigned : null,
            is_public: data.is_public ? data.is_public : null,
            admin_remark: data.admin_remark ? data.admin_remark : null,
            client_remark: data.client_remark ? data.client_remark : null,
            additional_remarks: data.additional_remarks
              ? data.additional_remarks
              : null,
            total: data.total ? data.total : null,
            sub_total: data.sub_total ? data.sub_total : null,
            discount_value: data.discount_value ? data.discount_value : null,
            discount_amount: data.discount_amount ? data.discount_amount : null,
            tax_amount: data.tax_amount ? data.tax_amount : null,
            adjustment: data.adjustment ? data.adjustment : null,
            discount_type: data.discount_type ? data.discount_type : null,
            discount_value_type: data.discount_value_type
              ? data.discount_value_type
              : null,
            date: data.date ? data.date : null,
            open_till: data.open_till ? data.open_till : null,
            date_assigned: data.date_assigned ? data.date_assigned : null,
            sent_date: data.sent_date ? data.sent_date : null,
            date_converted: data.date_converted ? data.date_converted : null,
            total_items: data.total_items ? data.total_items : null,
            added_by: data.added_by ? data.added_by : null,
            added_at: data.added_at ? data.added_at : null,
            updated_by: data.updated_by ? data.updated_by : null,
            updated_at: data.updated_at ? data.updated_at : null,
            created_at: data.created_at ? data.created_at : null,
            modified_at: data.modified_at ? data.modified_at : null,
            status_text: data.status_text ? data.status_text : null,
            line_items: data.line_items ? data.line_items : [],
            more_actions: data.more_actions ? data.more_actions : new Array(),
            documents: data.attachments ? data.attachments : new Array()
          });
          _this.customer = _this.proposalDetail.customer
            ? _this.proposalDetail.customer.id
            : null;
        })
        .catch(error => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Proposal", route: "proposal" },
      { title: "Detail" }
    ]);

    _this.proposal = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.proposal <= 0) {
      _this.goBack();
    }

    _this.getProposal();
  },
  computed: {
    proposalDeleteRequestUrl() {
      return "proposals/" + this.proposal;
    },
    proposalSubject() {
      return this.proposalDetail.subject;
    },
    lineItemCount() {
      return this.proposalDetail && this.proposalDetail.line_items
        ? this.proposalDetail.line_items.length
        : null;
    }
  }
};
</script>
